/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on August 5, 2024 */

@font-face {
  font-family: "pp_neue_montrealmedium";
  src: url("/fonts/webfontkit/ppneuemontreal-medium-webfont.woff2")
      format("woff2"),
    url("/fonts/webfontkit/ppneuemontreal-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "pp_neue_montrealthin";
  src: url("/fonts/webfontkit/ppneuemontreal-thin-webfont.woff2")
      format("woff2"),
    url("/fonts/webfontkit/ppneuemontreal-thin-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
