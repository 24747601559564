
body{padding:0px; margin:0px; font-family: 'pp_neue_montrealmedium';} 
.standardnbtdemo{background:#000;}
.item {padding:0px;box-sizing: border-box;color: white;font-size: 1.2em;}
img.lazy {opacity: 0; transition: opacity 0.3s ease-in-out;}
img.loaded {opacity: 1;}
.demovideos video{width:100%;}
.item-80 {flex: 7;}
.item-20 {flex: 3;}
.column {padding: 20px;box-sizing: border-box;  height: 100%;/* Include padding in the width calculation */color: white;font-size: 1em;}
.leftserviceimg img{width: 100%;height: 100%;object-fit: fill;object-fit: cover;object-position: 50% 50%;    border-radius: 10px;}
.rightcontent h2{font-weight:400; font-size:80px; color:#f0e3d5; text-transform:uppercase; text-align:right;justify-content: space-between;    word-wrap: break-word;}
.leftserviceimg{display:inline-block; width:100%; height:100%;}
.commontitle{    font-weight: 400;font-size:250px;color: #ff914d;text-transform: uppercase;text-align: left;display: inline-block;    margin: 0px;vertical-align: top;padding: 0 0%; line-height:280px;}
.rightcontent p{display:flex;  font-size:22px;    word-wrap: break-word; color:#f0e3d5;font-family: 'pp_neue_montrealthin'; text-align:justify;justify-content: space-between; line-height:30px;}
.rightcontent{height:100%; display: flex;flex-direction: column;justify-content: space-between; padding:5% 0%;box-sizing: border-box;}
.min_height{display:inline-block; width:100%; min-height:140px;}
.leftservice img {max-width: 100%; /* Ensure image does not exceed the container's width */height: auto; /* Maintain aspect ratio */object-fit: cover; /* Cover the image area without distorting */border-radius: 8px; /* Optional rounded corners for visual appeal */ width: 100%;height: auto;display: block;filter: blur(10px);transition: filter 0.6s ease, opacity 0.6s ease;opacity: 0;}
.leftservice{flex-shrink: 0; /* Prevent image from shrinking */display: flex;align-items: center; /* Center image vertically */}
.leftservice img {width: 100%;height: 100%;object-fit: fill;object-fit: cover;object-position: 50% 50%; width: 100%;
height: auto;display: block;filter: blur(10px);transition: filter 0.6s ease, opacity 0.6s ease;opacity: 0;}
.rightcontent_bottom{display: flex;width: 100%;justify-content: space-between;height: 100%;align-items: center;   }
.rightcontent_bottom{display: flex;align-items: center;justify-content: center;flex-direction: column;height: 100%;gap:30px;padding: 0 5%;}
.rightcontent_bottom p {display: flex;font-size: 22px;word-wrap: break-word;color: #f0e3d5; font-family: 'pp_neue_montrealthin';text-align: justify;justify-content: space-between;    padding: 0 17%;}
.animated-element {will-change: transform, opacity; /* Suggests to the browser what properties will change */transition: transform 0.6s ease, opacity 0.6s ease;}
.lazy {width: 100%;height: auto;display: block;margin-bottom: 20px;opacity: 0;transition: opacity 0.3s;}
.lazy.loaded {filter: blur(0);
opacity: 1;}
.lefttopsection h1{    font-weight: 400;font-size:170px;color: #ff914d;text-transform: uppercase;text-align: left;display: inline-block;vertical-align: top;padding:0 0% 4%; margin:0px;}
.demoImages{padding:0 0px; overflow:hidden;}
.demoImages img{width:100%;}
.margin{margin:15px 0;}
.marginsec{margin-bottom:15px;}
#videobcg1{max-width: 100%;width: 100%;margin: 0;line-height: 1;border: none;}
.ContactSection{display:inline-block; width:100%;}
.OfficeTime th{padding:0px; margin:0px;color:#fff; text-transform: uppercase;}
.OfficeTime td{padding:0px; margin:0px; color:#fff;font-family: 'pp_neue_montrealthin'; font-size:22px;}
.topsection{text-align:center; padding: 5% 0 5%;}
.topsection h3{display:inline-block; width:100%; color:#fff; font-size:50px;    text-transform: uppercase; margin:0px; font-weight:500;}
.topsection p{display:inline-block; width:100%; color:#fff; font-size:22px;    font-family: 'pp_neue_montrealthin'; margin:0px;}
.LeftsideAddress{display:inline-block; width:100%;}
.LeftsideAddress h4{color:#fff; font-size:20px;}
.LeftsideAddress label{color:#fff;}
.demovideos.mt40{margin-top:20%;}
.paddingRight .rightcontent{padding-left:10%;}
.footer_container p{display:inline-block; width:100%; font-size:15px;font-family: 'pp_neue_montrealthin'; }
.map{display:inline-block; width:100%; margin-top:30px;}
.servicestext h4,.servicestext label{font-weight: 400;font-size:121px;color: #f0e3d5;text-transform: uppercase;text-align: right;justify-content: space-between;word-wrap: break-word; text-align:left;  display:inline-block; margin-top: 20px; margin-bottom: 20px;}
.lefticons{display:inline-block; margin:10px 7px 0;}
.footer_container img{max-width:20px;}
.leftserviceImage img {max-width: 100%;object-fit: fill;object-fit: cover;object-position: 50% 50%;border-radius: 10px;    -webkit-transform: scale(1);
transform: scale(1); height:100%;
-webkit-transition: .3s ease-in-out;
transition: .3s ease-in-out;}
.leftserviceImage {display: inline-block;width: 100%;height: 100%; overflow:hidden;}
.MobileOpen{display:none;}
.submit_button .btn-primary-primary{    background: white;text-transform: uppercase;font-family: '__Oswald_6b88b2', '__Oswald_Fallback_6b88b2';border: 1px solid #ccc;color: #000;height: 48px;}
.leftserviceImage:hover img {-webkit-transform: scale(1.3);transform: scale(1.3);}
.demoImages img {  filter: none;-webkit-filter: grayscale(0);}

.demoImages img:hover {/* filter: url(filters.svg#grayscale); Firefox 3.5+ */filter: gray; /* IE5+ */-webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
-webkit-transition: all .8s ease-in-out;-webkit-transform: scale(1.01);}
.Topsection,.Servicessection{display:inline-block; width:100%;}
.mobileopen{display:none;}

.map iframe{max-width:100%;}

#back-top__content--desk {position: fixed;bottom: 70px;right: 30px;display: none;width: 50px;height: 50px;background-color: #333;color: #fff;
text-align: center;line-height: 50px;border-radius: 50%;font-size: 18px;cursor: pointer;transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;opacity: 0;
visibility: hidden;}

#back-top__content--desk.active {display: block;opacity: 1;visibility: visible;animation: fadeIn 0.5s ease-in-out;}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.rightcontent .mobileopen{display:none;}
.thankyou_header{color:#fff; margin:0px;}
.thankyou_content p{color:#fff;}
.thankyou_content a{color:#fff;}

.mobiledisplay{display:none;}
.CallBackButton{display:none; width:100%;position: fixed;bottom: 0px; z-index:2;}
.CallBackButton a{display: inline-block;width: 100%;background: #ff914d;height: 45px;color: #fff;text-align: center;line-height: 44px;text-decoration: none;
text-transform: uppercase;font-size: 18px;box-shadow: 12px -3px 15px #000000;}
.CallBackButtonheight{display:inline-block; width:100%; height:43px;}
.leftserviceImage.FootMobile{display:none;}




@media (max-width: 1440px) {
.lefttopsection h1{font-size:130px;}
.servicestext h4{ font-size:150px;}
.rightcontent h2{font-size:65px;}
.commontitle{    line-height: 255px; font-size:180px;}	
.rightcontent_bottom p{padding:0 4%;}

}

@media (max-width: 1366px) {}
@media (max-width: 1280px) {}
@media (max-width: 1024px) {
.lefttopsection h1 {font-size: 94px;}	
.servicestext h4 {margin: -157px 0 5% 0;font-size: 102px;}
.rightcontent h2,.servicestext h4{font-size:36px;}
.rightcontent p{font-size:16px; line-height:24px;}
.paddingRight .rightcontent {padding-left: 0%;}
.commontitle {        line-height: 155px;
        font-size: 121px;}
.rightcontent_bottom p{    padding: 0 3%; font-size:16px; line-height:24px;}
.topsection h3{font-size:35px;}
.topsection p{font-size:16px; line-height:24px; margin:30px 0 0 0;}
.min_height{    min-height: 20px;}
}

@media (max-width: 768px) {
.servicestext h4 {margin: 14% 0 0% 0;font-size: 102px;}	
.min_height{min-height:20px;}
.topsection p br{display:none;}
.MobileNone{display:none;}

.rightcontent_bottom{    padding: 0 0%;}


.column{padding: 0 15px;}
.LeftsideAddress{padding:0 15px;}
.mobiledisplay{display:block;}
.VideoNone{display:none;}
.mobiledisplay .demoImages{padding:0px;}
.mobiledisplay #videobcg1,.mobiledisplay #videobcg2{ width: 100%; height: 100%;object-fit: cover;}
.contactform_container_row .input-container>input,.contactform_container_row  .input-container>textarea{padding:12px;}
.footer_container{padding: 4% 0;}
.commontitle {line-height: 158px;font-size: 128px;}
.servicestext h4, .servicestext label{    font-size: 61px;}
.input-container>textarea{height: 77px;}
}

@media (max-width:668px) {
.leftserviceImage img{
    height: auto;
  }
  .leftserviceImage{
height: auto;
  }
.leftserviceImage.FootMobile{display:block;}	
.mobileNT{display:none;}
.rightcontent_bottom p{padding:0 0;}	
.CallBackButton{display:block;}
.MobileOpen{display:block;width: 100%;}
.rightcontent .mobileopen{display:block;}
.col-md-6.mobilenone{display:none;}	
.commontitle{line-height: 64px;font-size: 54px;text-align: center;display: inline-block;width: 100%;}
.lefttopsection h1 {font-size: 51px;display: inline-block;width: 100%;text-align: center;margin: 16% 0 11%;}
.servicestext h4 {font-size: 51px;display: inline-block;width: 100%;text-align: center;min-height: 160px;}
.rightcontent h2{text-align:center;}
.demoImages{padding:0px 15px;}
.containerxxl{padding:0px;}
.containerxxl .Letstalk {padding: 0;}
.col-md-6{width:100%;}
.rightcontent{padding:0px;}
.rightcontent h2{margin-top:24px;}
.column{    padding-bottom: 0px;}
.rightcontent p{margin-top: 8%;}
.containerxxl .Letstalk {padding: 0;margin-top: 30px;}
.Letstalk .input-container>textarea{height:67px;}
.layoutimg {min-height: auto;}
.mobilelayout{min-height:520px}
.mobileshoring{min-height: 380px;}
.mobilefull{min-height: 510px;}
.mobilelayout img{min-height:100%; height: auto;}
.mobileshoring img{min-height:100%; height: auto;}
.mobilefull img{min-height:100%; height: auto;}
.servicestext label{font-size: 51px;display: inline-block;width: 100%;text-align: center;min-height: 160px;}}
.MoveTop{margin-top:-35px;}









