p {
    margin: 0;
}

.section_banner {
    position: relative;
    height: 89vh;
}
/* Targeting the specific HubSpot label */
.hs_firstname label span {
    color: #ff0000; /* Replace #ff0000 with your desired color */
    font-weight: bold; /* Optional: make it bold if needed */
}



.section_banner::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000080;
    content: '';
    z-index: 2;
}

.section_banner .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
}

.section_banner .slick-slider.slick-initialized {
    z-index: 2;
}

.video-background video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.banner_tiltes {
    position: relative;
    z-index: 3;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 95vh; */
    gap: 25px
}

.banner_tiltes h2 {
    font-size: 43px;
    color: #fff;
    line-height: 50px;
    text-align: center;
    font-weight: 600;
}

.banner_tiltes p {
    font-size: 18px;
    color: #fff;
    line-height: 30px;
    text-align: center;
    font-weight: 500;
}

.view_product {
    border: 1px solid #FFFFFF !important;
    font-size: 16px;
    color: #FFFFFF !important;
    text-transform: inherit !important;
    border-radius: 10px;
    padding: 10px 17px;
    display: flex;
    gap: 10px;
}

.slickslider {
    position: relative;
    height: 100vh;
}

.slickslider .slider_position {
    position: absolute;
    width: 100%;
    top: 28%
}

.slider_position .slick-prev:before,
.slider_position .slick-next:before {
    opacity: 0;
}

.view_product:hover {
    padding: 10px 17px;
    box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
    position: relative;
    transition: 0.5s;
    z-index: 1;
}

.view_product svg {
    font-size: 20px;
}

.common_space {
    margin: 80px 0;
}


/* prodect we serve page css */

.product_title_Sec {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.product_title_Sec label {
    color: #000000;
    font-size: 28px;
    line-height: 28px;
    font-weight: 500;
}

.mechanical,
.electricl,
.water {
    height: 445px;
}

.ProductweServe .product_grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
    gap: 20px;
    margin-top: 35px;
}

.ProductweServe .mechanical,
.ProductweServe .electricl,
.ProductweServe .water {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding: 29px 45px 40px 45px;
    border-radius: 10px;
    flex-direction: column;
    gap: 15px;
    text-align: center;
    border: 1px solid #fff;
    position: relative;
}

.ProductweServe .mechanical .part_sec {
    margin-top: 50px;
}

.ProductweServe .electricl .part_sec {
    margin-top: 50px;
}

.ProductweServe .water .part_sec {
    margin-top: 50px;
}

.ProductweServe .mechanical {
    /* background-color: #F7FCEF; */
    position: relative;
}


/* .ProductweServe .mechanical::before{
 content: '';
 background-color: red;
 position: absolute;
 left:0;
 right: 0;
 left:0;
 bottom: 0;
 height: 100%;
 width: 100%;
} */

.ProductweServe .mechanical:before {
    content: '';
    background-color: #F7FCEF;
    position: absolute;
    left: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #fff;
}

.ProductweServe .mechanical:hover:before {
    transform: rotateY(180deg);
    transition: 0.8s;
    transform-style: preserve-3d;
    border: 1px solid #8DC63FB2;
}

.ProductweServe .electricl:before {
    content: '';
    background-color: #FAF4EA;
    position: absolute;
    left: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #fff;
}

.ProductweServe .electricl:hover:before {
    transform: rotateY(180deg);
    transition: 0.8s;
    transform-style: preserve-3d;
    border: 1px solid #FFC057;
}

.ProductweServe .water:before {
    content: '';
    background-color: #F0F5F9;
    position: absolute;
    left: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #fff;
}

.ProductweServe .water:hover:before {
    transform: rotateY(180deg);
    transition: 0.8s;
    transform-style: preserve-3d;
    border: 1px solid #99CAEC;
}

.mechengineer {
    position: relative;
    width: 100%;
}

.ProductweServe .mechanical:hover small {
    top: -18px;
}

.ProductweServe .electricl:hover small {
    top: 11px;
}

.ProductweServe .water:hover small {
    top: 11px;
}

.ProductweServe .mechanical:hover .part_sec {
    margin-top: 20px;
}

.ProductweServe .electricl:hover .part_sec {
    margin-top: 50px;
}

.ProductweServe .water:hover .part_sec {
    margin-top: 50px;
}


/* 
.ProductweServe .electricl {
 background-color: #FAF4EA;
} */


/* .ProductweServe .water {
 background-color: #F0F5F9;

} */

.mechanical ul,
.electricl ul,
.water ul {
    width: 100%;
    text-align: start;
    padding: 0 26px;
    display: none;
}

.mechanical ul li,
.electricl ul li,
.water ul li {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #8DC63FB2;
    padding: 12px 0;
}

.mechanical ul li span,
.electricl ul li span,
.water ul li span {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #8DC63FB2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mechanical ul li span {
    color: #fff;
}

.water ul li span {
    color: #0080B2;
    background-color: #0080B2;
    color: #fff;
}

.electricl ul li span {
    color: #E17F26;
    background-color: #E17F26;
    color: #fff;
}

.electricl ul li {
    color: #E17F26
}

.water ul li {
    color: #0080B2;
    text-wrap: nowrap;
}

.mechanical ul li span svg,
.electricl ul li span svg,
.water ul li span svg {
    font-size: 15px;
}

.ProductweServe .mechanical:hover {
    justify-content: end;
}

.ProductweServe .mechanical:hover img {
    display: none;
}

.ProductweServe .mechanical:hover p {
    display: none;
}

.ProductweServe .mechanical:hover ul {
    display: block;
}

.ProductweServe .electricl:hover img {
    display: none;
}

.ProductweServe .electricl:hover p {
    display: none;
}

.ProductweServe .electricl:hover ul {
    display: block;
}

.ProductweServe .water:hover img {
    display: none;
}

.ProductweServe .water:hover p {
    display: none;
}

.ProductweServe .water:hover ul {
    display: block;
}
.phone_button{
    font-size: 22px;
    color: #ff914d;
    text-decoration: none;
    vertical-align: middle;
}

.mechanical small {
    position: absolute;
    top: 114px;
    transition: top 0.3s ease, bottom 0.3s ease;
    display: flex;
    justify-content: center;
    width: 100%;
}

.electricl small {
    position: absolute;
    top: 120px;
    transition: top 0.3s ease, bottom 0.3s ease;
    display: flex;
    justify-content: center;
    width: 100%;
}

.water small {
    position: absolute;
    top: 114px;
    transition: top 0.3s ease, bottom 0.3s ease;
    display: flex;
    justify-content: center;
    width: 100%;
}


/* product detail section */

.Productwdetailsec {
    margin-bottom: 80px;
}

.mechanicaldetail {
    display: flex;
    gap: 40px;
    align-items: center;
}

.mechanicaldetail .inform_Sec {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 640px;
}

.mechanicaldetail .inform_Sec label {
    font-size: 16px;
    color: #000000;
    font-weight: 600;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}

.mechanicaldetail .inform_Sec label svg {
    font-size: 17px;
}

.mechanicaldetail .img_sec {
    max-width: 300px;
    position: relative;
    padding: 8px 8px 4px 8px;
}

.mechanicaldetail .img_sec>div {
    overflow: hidden;
    border-top-left-radius: 0;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 318px;
    /* border-radius: 10px; */
}

.mechanicaldetail .img_sec img {
    transition: 0.4s ease;
}

.mechanicaldetail .img_sec:hover img {
    transform: scale(1.08);
}

.mechanicaldetail .img_sec::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    border: 8px solid #A8DE5D;
    border-bottom: 0;
    border-right: 0;
    width: 176px;
    height: 176px;
    /* z-index: 9; */
    border-top-left-radius: 10px;
}

.mechanicaldetail .img_sec::after {
    position: absolute;
    content: '';
    bottom: -4px;
    right: 0;
    border: 8px solid #A8DE5D;
    border-left: 0;
    border-top: 0;
    width: 111px;
    height: 98px;
    /* z-index: 9; */
    border-bottom-right-radius: 10px;
}

.electricaldetail {
    flex-direction: row-reverse;
    justify-content: space-between;
}

.mechanicaldetail.electricaldetail .img_sec::before {
    border: 8px solid #FFC057;
    border-bottom: 0;
    border-left: 0;
    right: 0;
    left: auto;
    border-top-left-radius: 0;
    border-top-right-radius: 10px;
}

.mechanicaldetail.electricaldetail .img_sec::after {
    border: 8px solid #FFC057;
    /* border-left: 0; */
    /* border-top: 0; */
    border-right: 0;
    border-top: 0;
    left: 0;
    bottom: 2px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 10px;
}

.mechanicaldetail.electricaldetail .img_sec>div {
    border-top-right-radius: 0;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
}

.waterdetail .img_sec::before {
    border: 8px solid #99CAEC;
    ;
    border-bottom: 0;
    border-right: 0;
}

.waterdetail .img_sec::after {
    border: 8px solid #99CAEC;
    border-left: 0;
    border-top: 0;
}


/* Letstalk */

.Letstalk {
    max-width: 950px;
    margin: 0 auto;
    padding:32px 38px;
    background: #202020;    border-radius: 7px;
}

.Letstalk .form_Details .MuiBox-root {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(430px, 1fr));
    grid-gap: 10px;
}

.Letstalk .para_title {
    text-align: center;
}

.Letstalk .MuiAutocomplete-root {
    width: 100%;
    background: #fff;
    /* height: 100%; */
}

.Letstalk .MuiAutocomplete-inputRoot {
    padding: 6px 0;
}

.Letstalk .MuiAutocomplete-root .MuiOutlinedInput-notchedOutline {
    border: none;
}

.Letstalk .MuiInputLabel-formControl[data-shrink="true"] {
    display: none;
}

.Letstalk .MuiInputLabel-formControl[data-shrink="false"] {
    font-size: 14px;
    color: #151414bf;
    font-family: inherit;
}


/* .Letstalk .MuiAutocomplete-root .MuiChip-root.MuiChip-filled{ background: #F4F4F4;} */

.Letstalk .MuiAutocomplete-root .MuiAutocomplete-tag {
    position: relative;
    padding: 20px 0;
    color: #000000CC;
}

.Letstalk .MuiAutocomplete-root .MuiFormControl-root .MuiChip-filled .MuiChip-deleteIcon {
    opacity: 0;
    z-index: 9999;
}

.Letstalk .form_Details .text_Areasec .message_footer {
    width: 100%;
}

*/ .Letstalk .submit_button button.btn_bgPrimaryColor {
    position: relative;
    padding: 10px 0;
}

.contact_us_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 20px 0;
}

.contact_us_container_left,
.contact_us_container_right {
    width: 50%;
    height: 100%;
}

.contact_us_container_left {
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contact_us_heading {
    color: #101838;
    font-size: 28px;
    font-weight: 600;
    line-height: normal;
}

.contact_us_content {
    color: #101838;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
}

.contact_us_email {
    display: flex;
    gap: 10px;
    align-items: center;
    color: #101838;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    width: fit-content;
}

.contact_us_img {
    width: 100%;
    height: auto;
    margin-bottom: 50px;
}

.contactform_container {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    /* background: #FFF; */
    /* box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.03); */
    padding: 0px 0 0 0;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input-container>label {
    color: #8D8D8D;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
}

.input-container>input::placeholder,
.input-container>textarea::placeholder,
.Letstalk .custom_number_container .react-tel-input .form-control::placeholder {
    color: #151414bf;
    font-weight: 400;
    opacity: 1;
    z-index: 999;
}

.input-container>input,
.input-container>textarea {
    color: #011C2A;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 17px;
    border: none;
    /* border-bottom: 1px solid #8D8D8D; */
    /* width: 100%; */
    font-family: inherit;
    background: #fff;
    padding: 17px;
}

.input-container>textarea {
    resize: none;
}

.input-container>input:focus-visible,
.input-container>textarea:focus-visible {
    border: none;
    outline: none;
    border-bottom: 1px solid #226DB4;
}

.Letstalk .contactform_container_row {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.Letstalk .contactform_container_row .input-label {
    color: #8D8D8D;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.Letstalk .contactform_container_row .custom_number_container {
    width: 100%;
}

.Letstalk .contactform_container_row .react-tel-input .selected-flag:hover {
    background-color: #F4F4F4;
    ;
}


/* .Letstalk .contactform_container_row .react-tel-input {
 height: 100%;
} */

.Letstalk .custom_number_container .react-tel-input .form-control {
    width: 415px;
    color: #151414bf;
}

.Letstalk .custom_number_container .react-tel-input .form-control {
    width: 100%;
    border: none;
    /* border-bottom: 1px solid #8D8D8D; */
    border-radius: 0px;
    padding: 17px 80px;
    height: 100%;
}

.Letstalk .custom_number_container .react-tel-input .flag-dropdown {
    background-color: transparent;
    border: none;
    border-radius: 0px;
    padding: 8px 20px 10px 11px;
    ;
}

.Letstalk .custom_number_container .react-tel-input .selected-flag {
    position: relative;
}

.Letstalk .custom_number_container .react-tel-input .selected-flag::before {
    border-right: 1px solid #e2e2e2;
    content: '';
    position: absolute;
    left: 55px;
    height: 100%;
}

.Letstalk .contactform_container_row_bottom {
    flex-wrap: wrap;
}

.error-message {
    color: red;
    font-size: 13px;
    font-weight: 400;
    padding-top: 5px;
}

.contact_us_email_container {
    min-height: 25px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#rc-imageselect {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
}

.contactform_recaptcha_container iframe {
    transform: scale(0.7);
    transform-origin: 0 0;
}

.contactform_recaptcha_container>div>div>div {
    height: 56px !important;
}


/* client are saying */

.clientsaying {
    background-color: #F7F7F7;
    padding: 80px 0;
}

.clientsaying .title_Section {
    align-items: start;
}

.containersaying {
    max-width: 100%;
    /* margin: 0 0 0 125px; */
}

.containersaying .title_Section {
    margin-left: 125px;
}

.auto_complete {
    width: 100%;
}

.Letstalk label {
    font-size: 32px;
    color: #ff914d;
    font-weight: 500;
    text-align: center;
}

.Letstalk .title_Section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.auto_complete .Mui-error.MuiFormHelperText-sizeMedium {
    color: #d32f2f;
    background-color: #fff;
    /* z-index: 99999; */
    padding: 0;
    margin: 0;
    /* opacity: 57%; */
    font-weight: 400;
    font-family: inherit;
    display: none;
}

@media only screen and (max-width:1024px) {
    .section_banner {
        height: 30vh;
    }
    .slickslider {
        height: 15vh;
    }
    .section_banner {
        height: 30vh;
    }
    .slickslider {
        height: 25vh;
    }
    .banner_tiltes h2 {
        font-size: 25px;
        line-height: 100%;
    }
    .banner_tiltes {
        gap: 15px
    }
    .view_product {
        font-size: 11px;
        padding: 5px 10px;
        border-radius: 2px;
    }
    .Productwdetailsec {
        margin-bottom: 20px;
    }
    .slickslider .slider_position {
        top: 45%
    }
    .common_space {
        margin: 30px 0;
    }
    .ProductweServe .product_grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .ProductweServe .mechanical {
        max-width: 350px;
    }
    .ProductweServe .electricl {
        max-width: 350px;
    }
    .ProductweServe .water {
        max-width: 350px;
    }
    .clientsaying {
        padding: 40px 0;
    }
    .Letstalk {
        padding: 32px 38px;
    }
}

@media only screen and (max-width:925px) {
    .contact_us_container {
        flex-direction: column;
        margin-bottom: 60px;
    }
    .ProductweServe .mechanical,
    .ProductweServe .electricl,
    .ProductweServe .water {
        max-width: 310px;
    }
    .contact_us_container_left,
    .contact_us_container_right {
        width: 100%;
    }
    .slickslider {
        height: 22vh;
    }
}

@media only screen and (max-width:768px) {
    .mechanicaldetail,
    .water,
    .electricl {
        flex-direction: column;
    }
    .leftserviceImage img{height: auto;}
    .mobilelayout img{min-height: auto;}
}

@media only screen and (max-width:568px) {
    .contactform_container {
        padding: 20px 20px 30px 20px;
    }.mobilelayout img
    .Letstalk .contactform_container_row {
        flex-direction: column;
    }
    .Letstalk .contactform_container_row_bottom {
        align-items: flex-start;
    }
    .contact_us_container {
        margin-top: 24px;
        margin-bottom: 34px;
    }
    .contact_us_container_left {
        padding: 0;
    }
    .contact_us_img {
        margin-bottom: 40px;
    }
    .electricaldetail {
        flex-direction: column;
    }
    .waterdetail {
        flex-direction: column;
    }
    .mechanicaldetail {
        flex-direction: column;
    }
    .ProductweServe .mechanical,
    .ProductweServe .electricl,
    .ProductweServe .water {
        max-width: 310px;
        justify-content: space-around;
    }
    .Letstalk .custom_number_container .react-tel-input .form-control {
        width: 100%;
    }
    .containersaying {
        margin: 0 20px;
    }
    .containersaying .title_Section {
        margin-left: 20px;
    }
    .containerxxl {
        padding: 0 20px;
    }
    .nav_container_left a {
        height: 100%;
    }
    .slickslider {
        height: 15vh;
    }
    .section_banner {
        height: 30vh;
    }
    .slickslider {
        height: 15vh;
    }
    .mechanicaldetail .inform_Sec label {
        margin-bottom: 15px;
        font-size: 14px;
    }
}